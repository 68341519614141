import React, { useState } from 'react';
// import Masonry from 'react-masonry-component';
import AudioPlayer from "../../elements/audioPlayer"
import style from './SonyaImages.module.scss'

const SonyaImages = ({ items, onClick, opacity }) => {


  return(
    <>
      <div>
      {
        items.map((file, i) => {

          /* Check if filters mathch */
          const matchImage = new RegExp("^.*(jpg|JPG|gif|GIF|doc|DOC|pdf|PDF)$")
          const matchVideo = new RegExp("^.*(webm|mp4|mov)$")
          const matchAudio = new RegExp("^.*(mp3)$")
          const media = file.media

          const top = `${10 + Math.random() * 80}%`;
          const left = `${10 + Math.random() * 80}%`;

          const width = `${200 + Math.random() * 100}px`;
          const height = 'auto';

          console.log(file)

          return(
          
            <div 
            key={i} 
            className={style.indexMedia}
            data-value={file.filters.join("-")}
            onClick={() => onClick(i)} 
            // onKeyDown={handleClick} 
            role="interactive"
            style={{
              position: 'fixed',
              top,
              left,
              width,
              height,
              opacity: opacity ? 1 : 0,
            }}
            >
              {
                media.filename.match(matchAudio) ?
                <div className={style.audio}>
                  {/* <Audio src={media.filename} matches={filterMatches}/> */}
                  <AudioPlayer
                  file={media.filename}
                  // matches={filterMatches}
                  />
                </div>
                : false
              }
              {
                media.filename.match(matchImage) ?
                  <img src={media.filename} style={{
                  }}/>
                : false
              }
              {
                media.filename.match(matchVideo) ?
                  <video src={media.filename} style={{
                  }}/>
                : false
              }
            </div>
          )
        })
      }
      </div>
    </>
  )
}

export default SonyaImages