import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Sonya from "../../components/pages/sonya-schoenberger/sonya-schoenberger"
import AccessWrapper from '../../components/pages/AccessWrapper'

const SonyaPage = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='Sonya Schönberger & Nele Güntheroth' content={content.artists[5]} id={5}>
        <Sonya content={content} />
      </AccessWrapper>
    </Layout>
  )
}

export default SonyaPage

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`