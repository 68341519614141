import React, { useState } from "react";
import SonyaImages from './SonyaImages';
import style from './sonya-schoenberger.module.scss';
// import Slideshow from '../../layout/slideshow'
import Slideshow from '../../layout/slideshowNew'
import { SwiperSlide } from 'swiper/react';
import AudioPlayer from "../../elements/audioPlayer";
import RichText from '../../layout/richText'
import VideoPlayer from "../../elements/videoPlayer";

const Sonya = ({ content }) => {

  const background = content.SonyaBackground;
  const items = content.SonyaItems;

  const [ state, setState ] = useState({
    isSlideshowOpen: false,
    currentIndex: 0
  })

  const handleOnClick = (index) => {
    // console.log('open slideshwo')
    setState({
      ...state,
      isSlideshowOpen: true,
      currentIndex: index
    })
  }

  const handleClose = (index) => {
    // console.log('open slideshwo')
    setState({
      ...state,
      isSlideshowOpen: false,
    })
  }


  return(
      <div className="sonya">
        <div className={style.background}>
          <img src={background.filename} />
        </div>  
        {!state.isSlideshowOpen && <SonyaImages items={items} onClick={handleOnClick} opacity={!state.isSlideshowOpen} />}
        {
          state.isSlideshowOpen &&
          <div>
            <div className={style.overlay} />
            <div className={style.close} onClick={handleClose}>Back | Zurück</div>
            <Slideshow initialSlide={state.currentIndex}>
              {
                items.map((item, i) => {

                  // console.log(item.media.filename, item.media)
                  const matchImage = new RegExp("^.*(jpg|JPG|gif|GIF|doc|DOC|pdf|PDF)$")
                  const matchVideo = new RegExp("^.*(webm|mp4|mov)$")
                  const matchAudio = new RegExp("^.*(mp3)$")
                  const media = item.media

                  return (
                    <SwiperSlide key={i}>
                      <div className={style.slide}>
                        <div className={style.mediaWrapper}>
                        {
                          media.filename.match(matchAudio) ?
                          <div className={style.audio}> 
                            <AudioPlayer file={media.filename} autoPlay={false} looped={true} />
                          </div>
                          : false
                        }
                        {
                          media.filename.match(matchImage) ?
                            <img src={media.filename} />
                          : false
                        }
                        {
                          media.filename.match(matchVideo) ?
                            // <video src={media.filename} autoPlay={true} looped="true" muted="true" />
                            <div style={{ position: 'relative', width: '100%', height: '100%', textAlign: 'center' }}>
                              <VideoPlayer
                              video={media}
                              // isMuted={true}
                              isMuted={false}
                              padding={0}
                              className={style.video}
                              />
                            </div>
                          : false
                        }
                        </div>
                        {/* <img src={item.image} /> */}
                        <div className={style.caption}>
                          {/* <span>{i}</span> */}
                          <RichText text={item.Caption} /></div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Slideshow>

          </div>
        }
      </div>
  )
}

export default Sonya
